import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Form, Button, Modal, Accordion, Table} from 'react-bootstrap';
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Header from '../components/Header';
import UserService from '../services/UserService';
import HashUtil from '../services/HashUtil';

const serverConfig = require("../config/server.js");

const ApplicationDetails = () => {

  const navigate = useNavigate();

  const [mToken, setMToken] = useState(sessionStorage.getItem('token'));

  var url = window.location.href;
  var applicationID = url.substring(url.indexOf('?')+1).split('#')[0];
  console.log();
  console.log('applicationID', applicationID);

  const [appData, setAppData] = useState('');
  const [appDataEmailid, setAppDataEmailid] = useState('');
  const [PIData, setPIData] = useState('');
  const [documentationData, setDocumentationData] = useState('');
  //const [documentationValReport, setDocumentationValReport] = useState('');
  const [docValData, setDocValData] = useState([]);
  const [secondaryDocValData, setSecondaryDocValData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [lastUpdated, setLastUpdated] = useState('');
  const [isOpen, setIsOpen] = useState(null);

  /* Passport */
  const [showPassport, setShowPassport] = useState(false);
  const handleClosePassport = () => setShowPassport(false);
  const handleShowPassport = () => setShowPassport(true);
  /* Selfie Picture */
  const [showSelfiePicture, setShowSelfiePicture] = useState(false);
  const handleCloseSelfiePicture = () => setShowSelfiePicture(false);
  const handleShowSelfiePicture = () => setShowSelfiePicture(true);

  const [userPrivilegedViewLog, setUserPrivilegedViewLog] = useState(false);
  const[activityLog, setActivityLog] = useState([]);

  const formatDateTime = (datestr) => {
    var d = new Date(datestr);
    var dd = String(d.getDate()).padStart(2, '0');
    var mm = String(d.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = d.getFullYear();
    var hh = String(d.getHours()).padStart(2, '0');
    var min = String(d.getMinutes()).padStart(2, '0');
    var ss = String(d.getSeconds()).padStart(2, '0');
    return mm + '/' + dd + '/' + yyyy + ', ' + hh + ':' + min + ':' + ss;
  }

  const [kycRejected, setKycRejected] = useState(false);
  const [kycDetails, setKycDetails] = useState([]);

  const getToken = () => {
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, domain:window.location.hostname})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setMToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
    localStorage.clear();
  }

  const kycDetailsGet = () => {
    axios.post(serverConfig.api_base_url + "customer_kyc_status_get",{token:mToken, application_id:applicationID})
    .then(response=>{
      console.log("customer_kyc_status_get response:", response);
      console.log(response.data.status);
      if(response.data.status === 200){
        if(response.data.kyc_rejected === 1){
          setKycRejected(true);
        } else if(response.data.kyc_rejected === 0){
          setKycRejected(false);
        }

        if(response.data.items !== null && response.data.items !== ""){
          setKycDetails(response.data.items);
        } else {
          setKycDetails([]);
        }
      } else if(response.data.status === 407){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setMToken(sessionStorage.getItem('token'));
    }
  }, []);

  useEffect(() => {
    if(mToken !== null && mToken !== ''){
      /* application_data_get */
      console.log('mToken', mToken);

      axios.post(serverConfig.api_base_url + "user_check_privilege",{token: mToken, privilege: "submit_data"}).then(response=>{
        console.log('user_check_privilege response', response);
        if(response.data.status === 200) {
          if(response.data.is_privileged === 1){
            navigate("/customer");
          } else if(response.data.is_privileged === 0) {
            axios.post(serverConfig.api_base_url + "user_check_privilege",{token: mToken, privilege: "application_list_read"}).then(response=>{
              console.log('user_check_privilege response', response);
              if(response.data.status === 200) {
                if(response.data.is_privileged === 1){
                  axios.post(serverConfig.api_base_url + "get_app_data",{token:mToken, application_id:applicationID})
                  .then(response=>{
                    setIsLoading(false);
                    console.log(mToken);
                    console.log("get_app_data response:", response);
                    console.log(response.data.status);
                    if(response.data.status === 200){
                      setAppData(response.data.items);
                      setLastUpdated(response.data.last_updated_at);
                      setIsOpen(response.data.is_open);
                    } else if(response.data.status === 407){
                      handleLogout();
                    } else {
                      alert(response.data.message);
                    }
                  })
                  .catch(err=>console.log("error is",err));
                  console.log(appData);
                } else if(response.data.is_privileged === 0) {
                  alert("Unauthorized Access");
                  handleLogout();
                }
              } else if(response.data.status === 301){
                handleLogout();
              } else {
                alert(response.data.message);
              }
            })
            .catch(err=>console.log("error is",err));
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));

      /* axios.post(serverConfig.api_base_url + "customer_activity_log_get",{token: mToken, app_id:applicationID})
      .then(response=>{
        console.log('customer_activity_log_get', response);
        if(response.data.status === 200) {
          if(response.data.items !== null){
            setActivity(response.data.items.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)));
          } else {
            setActivity([]);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err)); */

      kycDetailsGet();

      // Check user privilege
      /* axios.post(serverConfig.api_base_url + "user_check_privilege", {token: mToken, privilege: "manual_override_kyc"})
      .then((response) => {
        if (response.data.status === 200) {
          if (response.data.is_privileged === 0) {
            setUserPrivilegedManualOverrideKyc(false);
          } else if (response.data.is_privileged === 1) {
            setUserPrivilegedManualOverrideKyc(true);
          }
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch((err) => console.log("error is", err)); */

      axios.post(serverConfig.api_base_url + "user_check_privilege", {token: mToken, privilege: "application_details_update"})
      .then((response) => {
        if (response.data.status === 200) {
          if (response.data.is_privileged === 0) {
            setUserPrivilegedEditApplication(false);
          } else if (response.data.is_privileged === 1) {
            setUserPrivilegedEditApplication(true);
          }
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch((err) => console.log("error is", err));

      axios.post(serverConfig.api_base_url + "user_check_privilege", {token: mToken, privilege: "application_log_view"})
      .then((response) => {
        if (response.data.status === 200) {
          if (response.data.is_privileged === 0) {
            setUserPrivilegedViewLog(false);
          } else if (response.data.is_privileged === 1) {
            setUserPrivilegedViewLog(true);
          }
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch((err) => console.log("error is", err));
    }
  }, [mToken]);

  useEffect(() => {
    if(userPrivilegedViewLog){
      axios.post(serverConfig.api_base_url + "application_data_log_get", {token: mToken, application_id: applicationID})
      .then((response) => {
        console.log('application_data_log_get', response)
        if (response.data.status === 200) {
          if (response.data.items !== null && response.data.items !== '') {
            setActivityLog(response.data.items);
          } else {
            setActivityLog([]);
          }
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch((err) => console.log("error is", err));
    }
  },[userPrivilegedViewLog]);

  const [userPrivilegedEditApplication, setUserPrivilegedEditApplication] = useState(false);
  const [userPrivilegedManualOverrideKyc, setUserPrivilegedManualOverrideKyc] = useState(false);
  const [showConfirmationOverrideKyc, setShowConfirmationOverrideKyc] = useState(false);
  const handleCloseConfirmationOverrideKyc = () => setShowConfirmationOverrideKyc(false);
  const handleShowConfirmationOverrideKyc = () => setShowConfirmationOverrideKyc(true);

  const handleOverrideKyc = () => {
    axios.post(serverConfig.api_base_url + "kyc_approve_manually", {token: mToken, app_id:applicationID})
    .then((response) => {
      if (response.data.status === 200) {
        handleCloseConfirmationOverrideKyc();
        kycDetailsGet();
      } else if (response.data.status === 301) {
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch((err) => console.log("error is", err));
  }

  const [imageData, setImageData] = useState(null);
  const [showImage, setShowImage] = useState(false);
  const handleCloseImage = () => {
    setShowImage(false);
  }
  
  return (
    <>
      <Header isLoggedIn={(mToken !== null && mToken !== undefined && mToken !== '') ? true : false} isAdmin={true} />
      <main className='application-details'>
        {isLoading && (<div id="loading"></div>)}
        <section>
          <Container fluid>
            <div className='page-title justify-content-space-between'>
              <div className='d-flex'>
                <div className='back-link'><Link to="/applications" className="btn-icon"><img src={require('./../assets/images/back-icon.png')} alt="Back" /></Link></div>
                <h1>Application Details</h1>
              </div>
              {isOpen === 1 && <b className='txt-red'>User submission pending !</b>}
              {isOpen === 0 && userPrivilegedEditApplication && <Button type="button" variant="primary" onClick={() => navigate('/application-details-edit?'+applicationID)} className='ms-auto'>Edit Information</Button>}
            </div>
          </Container>
        </section>
        {appData.length > 0 && appData.map((item, index) => {
          return(item.group_disabled === 0 && item.group_name !== "review_and_edit" && <section className='bdr-btm' style={{backgroundColor: (index % 2) == 1 ? "#efefef" : ""}}>
            <Container fluid>
              <h2>
                {item.group_name === "agreements_disclosures" && <img src={require('./../assets/images/agreements-icon.png')} alt="Agreements Icon" className='me-4' />}
                {item.group_label}
              </h2>
              
              {item.group_sections.length > 0 && item.group_sections.sort((a,b) => a.section_seq_no - b.section_seq_no).map((sectionItem, sectionIndex) => {
                return(sectionItem.section_visible === 1 && <div className='mb-3'>
                  {(sectionItem.section_title && item.group_name !== "agreements_disclosures") && <h3 style={{backgroundColor: "#cccccc"}} className='p-2'>{sectionItem.section_title}</h3>}
                  {sectionItem.section_entities.length > 0 && <Row xs={(sectionItem.section_name === "beneficial_owner_information" || item.group_name === "agreements_disclosures") ? 12 : 4}>
                    {sectionItem.section_entities.sort((a,b) => a.entity_seq_no - b.entity_seq_no).map((entityItem, entityIndex) => {
                      return(<>
                        {entityItem.entity_visible === 1 && <Col className='mt-2 mb-2'>

                          {item.group_name === "payment" && <>{<img src={entityItem.entity_data === "true" ? require('./../assets/images/check-icon.png') : require('./../assets/images/uncheck-icon.png')} alt="Check Icon" className='me-2' />}<span>Payment Complete</span></>}

                          {item.group_name === "agreements_disclosures" && sectionItem.section_name !== "application_review" && <>{<img src={entityItem.entity_data === "true" ? require('./../assets/images/check-icon.png') : require('./../assets/images/uncheck-icon.png')} alt="Check Icon" className='me-2' />}<span dangerouslySetInnerHTML={{__html:entityItem.entity_label || entityItem.entity_name}} /></>}

                          {item.group_name === "agreements_disclosures" && sectionItem.section_name === "application_review" && <>
                            <span className='label' dangerouslySetInnerHTML={{__html:entityItem.entity_label || entityItem.entity_name}} />: {entityItem.entity_name === "rating" ? <div className="star-rating">
                              {[...Array(5)].map((star, index) => {
                                index += 1;
                                return (
                                  <Button variant="star" type="button" key={index} className={index <= entityItem.entity_data ? "on" : "off"}></Button>
                                );
                              })}
                            </div> : entityItem.entity_data}
                          </>}

                          {item.group_name !== "payment" && item.group_name !== "agreements_disclosures" && <>
                            <span className='label' dangerouslySetInnerHTML={{__html:entityItem.entity_label || entityItem.entity_name}} /> :
                            <div className='value' style={{maxHeight: entityItem.entity_data_type.verify_id === 1 ? "100px" : "none"}}>
                              {
                                entityItem.entity_data_type.type === "image" ? entityItem.entity_data && <img src={'data:image/jpeg;base64,'+entityItem.entity_data} height="80" alt="Image" type="button" onClick={() => {setImageData('data:image/jpeg;base64,'+entityItem.entity_data); setShowImage(true);}} /> 
                                : <>
                                  {entityItem.entity_data_type.boInfo === 1 ? entityItem.entity_data && <Accordion>
                                    {JSON.parse(entityItem.entity_data).map((boItem, boIndex) => {
                                      console.log(boItem);
                                      return(<Accordion.Item eventKey={`${boIndex}`} className="mb-3">
                                        <Accordion.Header>Beneficial Owner #{boIndex+1}</Accordion.Header>
                                        <Accordion.Body>
                                          <Row xs={5}>
                                            <Col><span className='label'>Parent/Guardian information instead of minor child</span> : {boItem.isExemptEntity !== '' ? "" : boItem.isParentGuardianInformation}</Col>
                                            <Col><span className='label'>FinCEN ID</span> : {boItem.isExemptEntity !== '' ? "" : boItem.boFincenId}</Col>
                                            <Col><span className='label'>Exempt entity</span> : {(boItem.boFincenId === null || boItem.boFincenId === '') ? boItem.isExemptEntity : ""}</Col>
                                            <Col><span className='label'>Last Name (or legal entity name of Beneficial Owner)</span> : {boItem.boFincenId !== '' ? "" : boItem.boIndividualLastNameEntityLegalName}</Col>
                                            <Col><span className='label'>First name</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boFirstName}</Col>
                                            <Col><span className='label'>Middle name</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boMiddleName}</Col>
                                            <Col><span className='label'>Suffix</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boSuffix}</Col>
                                            <Col><span className='label'>Date of birth</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boDateOfBirth}</Col>
                                          </Row>
                                          <hr />
                                          <h3>Residential address</h3>
                                          <Row xs={5}>
                                            <Col><span className='label'>Country/Jurisdiction</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boResidentialCountryJurisdiction}</Col>
                                            <Col><span className='label'>Address (number, street, and apt. or suite no.)</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boResidentialAddress}</Col>
                                            <Col><span className='label'>State</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boResidentialState}</Col>
                                            <Col><span className='label'>City</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boResidentialCity}</Col>
                                            <Col><span className='label'>ZIP/Foreign postal code</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boResidentialZipcode}</Col>
                                            <Col><span className='label'>Social security number (or similar number if non-U.S. person)</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boSSN}</Col>
                                          </Row>
                                          <hr />
                                          <h3>Form of identification and issuing jurisdiction</h3>
                                          <Row xs={5}>
                                            <Col><span className='label'>Identifying document type</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boDocumentIdType}</Col>
                                            <Col><span className='label'>Identifying document number</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boDocumentIdNumber}</Col>
                                          </Row>
                                          <hr />
                                          <h3>Identifying document issuing jurisdiction</h3>
                                          <Row xs={5}>
                                            <Col><span className='label'>a. Country/Jurisdiction</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boDocumentIdIssuingCountryJurisdiction}</Col>
                                            <Col><span className='label'>b. State</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boDocumentIdIssuingState}</Col>
                                            <Col><span className='label'>c. Local/Tribal</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boDocumentIdIssuingLocalTribal}</Col>
                                            <Col><span className='label'>d. Other local/Tribal description</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boDocumentIdIssuingLocalOtherTribe}</Col>
                                          </Row>
                                          <p className='mb-0 mt-2'><b>Identifying document</b></p>
                                          <Row>
                                            <Col><span className='label'>Identifying document image</span> : {(boItem.boFincenId !== '' || boItem.isExemptEntity !== '') ? "" : boItem.boIdentifyingDocumentImageBase64 && <img src={boItem.boIdentifyingDocumentImageBase64} height="80" alt="Image" type="button" onClick={() => {setImageData(boItem.boIdentifyingDocumentImageBase64); setShowImage(true);}} />}</Col>
                                          </Row>
                                        </Accordion.Body>
                                      </Accordion.Item>)
                                    })}
                                  </Accordion>
                                  :
                                  entityItem.entity_data}
                                </>
                              }
                            </div>
                          </>}
                        </Col>}
                      </>)
                    })}
                  </Row>}
                </div>)
              })}
            </Container>
          </section>)
        })}
        <section className='pt-5 pb-5 bdr-btm'>{/* buttons */}
          <Container fluid>
            <Button variant="transparent" className='w-auto' onClick={() => navigate('/applications')}>Cancel</Button>
            {/* {kycDetails.kyc_rejected === 1 && kycDetails.kyc_attempt_exceeded === 1 && kycDetails.kyc_approved_manually !== 1 && <Button variant="primary" className='ms-3 w-auto' onClick={handleShowConfirmationOverrideKyc} disabled={!userPrivilegedManualOverrideKyc}>Manual Override KYC</Button>}
            {kycDetails.kyc_approved_manually === 1 && <span className='ms-3 fs-5'><b>KYC approved manually!</b></span>} */}
            <span className='float-end'><span className='label'>Last Updated:</span> {lastUpdated}</span>

            <Modal show={showConfirmationOverrideKyc} onHide={handleCloseConfirmationOverrideKyc} backdrop="static"keyboard={false} centered className='content-text'>
              <Modal.Header closeButton>
                <Modal.Title><img src={require('./../assets/images/confirmation-icon.png')} alt="Confirmation Icon" className='me-3' />Confirmation</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Are you sure you want to Manually Override KYC for</p>
                <p>{appData.firstName} {appData.middleName ? appData.middleName+' ' : null}{appData.lastName}</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="transparent" onClick={handleCloseConfirmationOverrideKyc}>No</Button>
                <Button variant="primary" className='ms-3' onClick={handleOverrideKyc}>Yes</Button>
              </Modal.Footer>
            </Modal>
          </Container>
        </section>

        {userPrivilegedViewLog && <section className='pt-5 pb-5'>
          <Container fluid>
            <h2>Activity Log</h2>
            <div>
              {activityLog.length > 0 ? <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Entity Name</th>
                    <th>Data</th>
                    <th>Updated By</th>
                    <th>Updated At</th>
                  </tr>
                </thead>
                <tbody>
                  {activityLog.sort( (a,b) => b.id - a.id ).map((item, index) => {
                    return(<tr>
                      <td>{item.entity_name}</td>
                      <td>{item.entity_data}</td>
                      <td>{item.updated_by}</td>
                      <td>{item.updated_at}</td>
                    </tr>)
                  })}
                </tbody>
              </Table> : <div className='text-center'>
                <p>No activity yet</p>
                <img src={require('./../assets/images/no-activity.png')} alt="No activity" className='img-fluid' />
              </div>}
            </div>
          </Container>
        </section>}
      </main>

      <Modal className='show-picture' show={showImage} onHide={handleCloseImage} backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {imageData && <img src={imageData} alt="Image" className='img-fluid' />}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ApplicationDetails;
